<template>
  <div class="w-full pt-5 pb-5">
    <div class="flex">
      <back-button
        label="Back"
        @onClick="$router.back()"
        variant="secondary"
        class="mr-4"
      />
      <div
        class="mt-4"
        style="border-left: 1px solid #878e99; height: 1.5rem"
      />
      <div>
        <h1 class="text-xl text-left mr-4 font-extrabold ml-4 mt-3">Pension</h1>
      </div>
      <div class="w-7/12 mt-4">
        <Breadcrumb :items="breadcrumbs" />
      </div>
    </div>

    <div style="height: 100%" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>

    <div class="px-3" v-else>
      <card class="p-5 mt-6">
        <div class="border rounded border-romanSilver border-dashed p-3">
          <p class="font-bold mb-5 text-lg">Payrun Information</p>
          <div class="flex w-full gap-3">
            <card class="p-3 w-52">
              <h6 class="uppercase mb-2 font-bold mb-2 text-sm text-darkPurple">
                Pay run Type
              </h6>
              <h6 class="font-semibold text-sm text-jet uppercase">
                {{ payrunSummary.payrunType }}
              </h6>
            </card>
            <card class="p-3 ml-2">
              <h6 class="uppercase font-bold text-sm mb-2 text-blueCrayola">
                Pay Cycle Details
              </h6>
              <div class="flex">
                <div class="flex">
                  <h6 class="font-semibold mt-2 text-sm text-jet uppercase">
                    Pay Cycle:
                  </h6>
                  <span
                    class="
                      px-2
                      py-1
                      ml-4
                      mr-4
                      text-blueCrayola
                      capitalize
                      font-semibold
                      text-sm
                    "
                    style="
                      background: rgba(33, 118, 255, 0.15);
                      border-radius: 5px;
                    "
                  >
                    {{ payrunSummary.payCylce }}
                  </span>
                </div>

                <div class="flex">
                  <h6
                    class="font-semibold mt-2 ml-2 text-sm text-jet uppercase"
                  >
                    Pay frequency:
                  </h6>
                  <span
                    class="
                      px-2
                      py-1
                      ml-4
                      mr-4
                      text-blueCrayola
                      capitalize
                      font-semibold
                      text-sm
                    "
                    style="
                      background: rgba(33, 118, 255, 0.15);
                      border-radius: 5px;
                    "
                  >
                    {{
                      payrunSummary.payFrequency === "twice_monthly"
                        ? "Twice Monthly"
                        : payrunSummary.payFrequency === "weekly"
                        ? "Four Times Monthly"
                        : payrunSummary.payFrequency
                    }}
                  </span>
                </div>
                <div class="flex">
                  <h6
                    class="font-semibold text-sm mt-2 ml-2 text-jet uppercase"
                  >
                    Payment Schedule:
                  </h6>
                  <span
                    class="
                      px-2
                      py-1
                      mx-4
                      text-blueCrayola
                      capitalize
                      font-semibold
                      text-sm
                    "
                    style="
                      background: rgba(33, 118, 255, 0.15);
                      border-radius: 5px;
                    "
                  >
                    {{ `${$getOrdinal(payrunSummary.payPosition)} Payment` }}
                  </span>
                </div>
              </div>
            </card>
            <card class="w-auto p-2">
              <p
                class="
                  uppercase
                  text-mediumSeaGreen
                  mt-2
                  font-extrabold
                  text-sm
                "
              >
                Categories
              </p>
              <p class="text-sm uppercase">
                {{ payrunSummary.categories }}
              </p>
            </card>
          </div>
        </div>

        <div class="flex justify-between mt-5">
          <div class="flex gap-5 flex-1">
            <card class="w-auto p-3 pr-8">
              <p class="uppercase text-romanSilver font-extrabold text-xs">
                Due Date
              </p>
              <p class="text-lg text-darkPurple font-bold uppercase">
                {{ payrunSummary.dueDate }}
              </p>
            </card>
            <card class="w-auto p-3 pr-8">
              <p class="uppercase text-romanSilver font-extrabold text-xs">
                No. of Employees
              </p>
              <p class="text-lg text-darkPurple font-bold uppercase">
                {{ payrunSummary.employeeNo }}
              </p>
            </card>
            <card class="w-auto p-3 pr-8">
              <p class="uppercase text-romanSilver font-extrabold text-xs">
                Total Pension
              </p>
              <p class="text-lg text-darkPurple font-bold uppercase">
                {{ convertToCurrency(payrunSummary.totalPension) }}
              </p>
            </card>
          </div>
        </div>
        <div class="border w-full bg-romanSilver mt-8" />
      </card>
    </div>

    <div class="px-3" v-if="!loading">
      <card class="p-5 mt-6">
        <div class="p-3">
          <span> Track, view and manage PFA remittances. </span>
        </div>
        <template v-slot:footer>
          <CardFooter>
            <template v-slot:search>
              <SearchInput
                placeholder="Search"
                class="search"
                style="width: 300px; margin-top: 0.1rem"
              />
            </template>
            <template v-slot:calendar>
              <div class="flex" style="width: 180px; padding: 5px 8px">
                <icon icon-name="calendar" class="icon mt-1" />
                <div
                  class="border-r"
                  style="width: 1px; margin: 8px 8px; border-color: #321c3b"
                />
                <div
                  class="text-darkPurple h-full"
                  style="width: 91px; font-size: 16px; margin: 4px 6px"
                >
                  Year to Date
                </div>
                <icon icon-name="caret-down" class="icon mt-1" />
              </div>
            </template>
          </CardFooter>
        </template>
      </card>
      <div class="w-full mt-10">
        <sTable
          :headers="pfaHeader"
          :items="pfaTable"
          class="w-full mt-1"
          :has-checkbox="false"
          :has-number="false"
          :loading="false"
        >
          <template v-slot:item="{ item }">
            <div style="width: 128px" v-if="item.amount">
              <Badge
                :label="convertToCurrency(item.data.amount)"
                variant="primary"
                background-color="rgba(33, 118, 255, 0.15)"
                color="#2176FF"
                style="
                  padding: 8px;
                  height: 33px;
                  font-size: 14px;
                  font-weight: 600;
                "
              />
            </div>
            <div v-else-if="item.status">
              <Badge
                label="Outstanding"
                variant="primary"
                background-color="rgba(234, 60, 83, 0.08)"
                color="#EA3C53"
                style="
                  padding: 8px;
                  height: 33px;
                  font-size: 14px;
                  font-weight: 600;
                "
                v-if="item.data.status === 'pending'"
              />
              <Badge
                label="Remitted"
                variant="primary"
                background-color="rgba(19, 181, 106, 0.08)"
                color="#13B56A"
                style="
                  padding: 8px;
                  height: 33px;
                  font-size: 14px;
                  font-weight: 600;
                "
                v-else
              />
            </div>
            <div v-else-if="item.id">
              <Menu left top="-130" class="p-0" margin="40">
                <template v-slot:title>
                  <icon icon-name="more_icon" size="xs" />
                </template>
                <div style="width: 208px; height: 81px" class="py-3 px-2">
                  <div
                    @click="
                      $router.push({
                        name: 'ViewEmployees',
                        params: {
                          id: item.data.id,
                          idNo: payrunSummary.payrunNo,
                        },
                        query: {
                          name: item.data.pfa,
                        },
                      })
                    "
                    class="cursor-pointer h-8 flex"
                  >
                    <icon
                      icon-name="icon-eye"
                      size="xsm"
                      class="-mt-1 -ml-2 text-blueCrayola"
                      style="padding: 3px 10px; width: 36px; height: 36px"
                    />
                    <p class="pt-1" style="font-size: 14px">View</p>
                  </div>

                  <div
                    class="flex more h-8 cursor-pointer"
                    @click="handleViewSummary(item.data)"
                  >
                    <icon
                      icon-name="icon-eye"
                      size="xsm"
                      class="-mt-1 -ml-2 text-mediumSeaGreen"
                      style="padding: 3px 10px; width: 36px; height: 36px"
                    />
                    <p class="pt-1" style="font-size: 14px">PFA Summary</p>
                  </div>
                </div>
              </Menu>
            </div>
          </template>
        </sTable>
      </div>
    </div>

    <Modal v-if="showProcessModal">
      <Card class="p-5 flex flex-col max-w-lg">
        <div>
          <h4 class="mb-6 text-romanSilver">
            You can remit pension offline through any other means. Providing
            details of your offline remittance helps the system properly account
            for your payroll costs.
          </h4>
          <div class="flex justify-end">
            <Button
              background-color="none"
              class="text-dynamicBackBtn border border-solid border-dynamicBackBtn"
              width="96px"
              @click="showProcessModal = false"
            >
              Cancel
            </Button>
            <Button class="ml-4 text-white"
            :background="appearance.buttonColor || defaultBTNColor"
              @click="proceed"
            >
              Proceed
            </Button>
          </div>
        </div>
      </Card>
    </Modal>
    <RightSideBar
      v-if="showRemitForm"
      @close="showRemitForm = false"
      submit="Submit"
      @submit="handleSubmit"
      button-class="bg-dynamicBackBtn text-white"
    >
      <template v-slot:title>
        <p class="text-darkPurple">Remit Offline</p>
      </template>
      <template v-slot:subtitle>
        <p class="text-base font-semibold mb-4">
          Providing details of your offline remittance helps the system properly
          account for your payroll costs.
        </p>
      </template>
      <p class="text-jet text-sm mr-5">Effective Date</p>
      <div class="date-flex">
        <datepicker
          placeholder="--Start Date--"
          input-class="date-input "
          style="width: 100%"
          v-model="offline.paidAt"
        />
      </div>
      <div class="mt-4">
        <c-select
          placeholder="--Select--"
          :options="banks"
          variant="w-full"
          label="Select Bank (Optional)"
          v-model="offline.paymentSource"
        />
      </div>
      <div class="mt-4">
        <c-text
          placeholder="--Enter--"
          variant="w-full"
          label="Transaction ID (Optional)"
          v-model="offline.paymentReferrenceId"
        />
      </div>
    </RightSideBar>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ViewPFA",
  components: {
    CText: () => import("@scelloo/cloudenly-ui/src/components/text"),
    CSelect: () => import("@scelloo/cloudenly-ui/src/components/select"),
    SearchInput: () =>
      import("@scelloo/cloudenly-ui/src/components/search-input"),
    Breadcrumb: () =>
      import("@scelloo/cloudenly-ui/src/components/bread-crumb"),
    Badge: () => import("@scelloo/cloudenly-ui/src/components/badge"),
    STable: () => import("@scelloo/cloudenly-ui/src/components/table"),
    BackButton: () =>
      import("@scelloo/cloudenly-ui/src/components/back-button"),
    Card: () => import("@/components/Card"),
    Menu: () => import("@/components/Menu"),
    Button: () => import("@/components/Button"),
    Icon: () => import("@/components/Icon"),
    CardFooter: () => import("@/components/CardFooter"),
    Modal: () => import("@/components/Modal"),
    RightSideBar: () => import("@/components/RightSideBar"),
    Datepicker: () => import("vuejs-datepicker"),
  },
  data() {
    return {
      breadcrumbs: [
        {
          disabled: false,
          text: "Payroll",
          href: "payroll",
          id: "Payroll",
        },
        {
          disabled: false,
          text: "Compliance",
          href: "Compliance",
          id: "compliance",
        },
        {
          disabled: false,
          text: "Pensions",
          href: "PayRoll Pension",
          id: "pensions",
        },
        {
          disabled: false,
          text: this.$route.query.name,
          id: "Oak Pensions",
        },
      ],
      offline: {
        paidAt: null,
        paymentSource: "",
        paymentReferrenceId: "",
      },
      loading: true,
      showProcessModal: false,
      showRemitForm: false,
      pfaHeader: [
        { title: "PFA", value: "pfa" },
        { title: "Organization PFA Code", value: "pfaCode" },
        { title: "NUmber of Employees", value: "employeesNo" },
        { title: "Total Amount", value: "amount" },
        { title: "Status", value: "status" },
        { title: "", value: "id", image: true },
      ],
      pfaTable: [],
      payrunSummary: {
        payrunType: "",
        payCylce: "",
        payFrequency: "",
        paySchedule: "",
        payPosition: "",
        categories: "",
        dueDate: "",
        employeeNo: "",
        totalPension: "",
      },
    };
  },

  methods: {
    async handleViewSummary(item) {
      try {
        await this.$handlePrivilege("pension", "viewPfaSummary");
        this.$router.push({
          name: "Pension Details",
          params: {
            id: item.id,
          },
          query: {
            name: item.pfa,
          },
        });
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },
    handleSubmit() {
      this.loading = true;
      this.showRemitForm = false;
      this.offline.payrunNo = this.$route.query.payNo;
      this.offline.pfaId = this.$route.params.id;
      this.$_payPensionOffline(this.offline)
        .then(() => {
          this.$router.push({ name: "PayRoll Pension" });
          this.$toasted.success(`offline remit details saved successully.`, {
            duration: 3000,
          });
        })
        .catch((err) => {
          this.loading = false;
          const message = err ? err : "failed.";
          this.$toasted.error(message, {
            duration: 3000,
          });
        });
    },
    remitOffline() {
      this.showProcessModal = true;
    },
    proceed() {
      this.showProcessModal = false;
      this.showRemitForm = true;
    },
    resetDate(value) {
      if (value) {
        const dateValue = value;

        const split = dateValue.split("T00:00:00.000Z");

        return `${split[0]}T13:00:00.000Z`;
      }
      return "";
    },
  },

  computed: {
    ...mapState({
      banks: (state) =>
        state.Banks.map((bank) => ({
          ...bank,
          id: bank.code,
        })),
      defaultBTNColor: (state) => state.orgAppearance.defaultBtnColor,
      appearance: (state) => state.orgAppearance.orgAppearanceData,
    }),
  },

  mounted() {
    this.$store.dispatch("getBanks");

    this.$_getPfaPayruns(this.$route.params.id).then((result) => {
      const tableData = result.data.pfaLists;

      this.payrunSummary.payrunType = result.data.payrun.payType;
      this.payrunSummary.payCylce = `${this.$DATEFORMAT(
        new Date(`${result.data.payrun.year}-${result.data.payrun.month}-01`),
        `MMMM yyyy`
      )} Cycle`;
      this.payrunSummary.payFrequency = result.data.payrun.payFrequency;
      this.payrunSummary.paySchedule = "";
      this.payrunSummary.dueDate =
        result.data.payrun.payrollStatus === "paid"
          ? `${this.$DATEFORMAT(
              new Date(`${result.data.payrun.paidAt}`),
              `MMMM dd, yyyy`
            )}`
          : `${this.$DATEFORMAT(
              new Date(this.resetDate(`${result.data.payrun.payDate}`)),
              `MMMM dd, yyyy`
            )}`;
      this.payrunSummary.employeeNo = "";
      this.payrunSummary.totalPension = result.data.payrun.totalPension;
      this.payrunSummary.payPosition = result.data.payrun.paySplitPosition;
      this.payrunSummary.payrunNo = result.data.payrun.payrunNo;

      let sum = 0;

      tableData.forEach((data) => {
        sum += data.employeeCount;
        this.pfaTable.push({
          id: data.pfaId,
          pfa: data.pfaDetails.name,
          pfaCode: data.pfaDetails.pfaNo,
          amount: data.totalPensionAmount,
          employeesNo: data.employeeCount,
          status: data.pensionStatus,
        });
      });

      this.payrunSummary.employeeNo = sum;

      this.loading = false;
    });
  },
};
</script>

<style scoped>
.icon {
  width: 24px;
  height: 24px;
  padding: 3px;
}
</style>
